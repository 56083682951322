<template>
  <header>
    <div class="header-top p-80">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-2">
            <ul>
              <li v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
              </li>
            </ul>
          </div>
          <div class="col-lg-10 col-md-10">
            <div id="nav">
              <router-link :to="{name: 'Home'}" :title="$t('navigation.home')">{{ $t('navigation.home') }}</router-link>
              <router-link :to="{name: 'About'}" :title="$t('navigation.about')">{{ $t('navigation.about') }}</router-link>
              <router-link :to="{name: 'Services'}" :title="$t('navigation.services')">{{ $t('navigation.services') }}</router-link>
              <router-link :to="{name: 'Portfolio'}" :title="$t('navigation.portfolio')">{{ $t('navigation.portfolio') }}</router-link>
              <router-link :to="{name: 'Technologies'}" :title="$t('navigation.technologies')">{{ $t('navigation.technologies') }}</router-link>
              <router-link :to="{name: 'Contact'}" :title="$t('navigation.contact')">{{ $t('navigation.contact') }}</router-link>
            </div>
            <!--Mobile navigation-->
            <div id="mobile-nav">
              <div class="menu">
                <h6>Menu</h6>        
              </div>
              <div class="mobile-nav-body">
                <div class="mobile-nav-content">
                  <router-link :to="{name: 'Home'}" :title="$t('navigation.home')">{{ $t('navigation.home') }}</router-link>
                  <router-link :to="{name: 'About'}" :title="$t('navigation.about')">{{ $t('navigation.about') }}</router-link>
                  <router-link :to="{name: 'Services'}" :title="$t('navigation.services')">{{ $t('navigation.services') }}</router-link>
                  <router-link :to="{name: 'Portfolio'}" :title="$t('navigation.portfolio')">{{ $t('navigation.portfolio') }}</router-link>
                  <router-link :to="{name: 'Technologies'}" :title="$t('navigation.technologies')">{{ $t('navigation.technologies') }}</router-link>
                  <router-link :to="{name: 'Contact'}" :title="$t('navigation.contact')">{{ $t('navigation.contact') }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </header>
</template>


<script>
import i18n from '../i18n.js'

  export default {
    name: 'Header',
    data() {
      return {
        languages: [
          { flag: 'sk', language: 'sk'},
          { flag: 'gb', language: 'en' },
          { flag: 'de', language: 'de'}
        ],
      };
    },
    methods: {
      changeLocale(locale) {
        i18n.locale = locale;
      }
    },
    mounted(){
      global.jQuery = require('jquery');
      var $ = global.jQuery;
      window.$ = $;


      // Jquery functions
      $(function() {

         /**
       * Mobile menu
       */
      
        let mobile_menu_btn = $('#mobile-nav');
        let mobile_menu = $('.mobile-nav-content').hide();

        mobile_menu_btn.on('click', function(){
            mobile_menu.slideToggle();
        });

      });
    }
  }
</script>

<style lang="scss">
@import '../assets/scss/_variables.scss';
@import '../assets/scss/_mixins.scss';

.header-top {
  background: $bg;
  position: relative;
  z-index: 999;
  .container{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ul{
    @media #{$very_small}{
      text-align: center;
    }
    li{
      display: inline-block;
      margin-left: 12px;
      &:first-child{
        margin-left: 0px;
      }
      &:hover{
        cursor: pointer;
      }
      @media #{$very_small}{
      margin-top: 24px;
    }
    }
  }
}

#nav {
  padding: 24px 0px;
  text-align: right;
  @media #{$very_small}{
    display: none;
  }
  a {
    margin-left: 12px;
    font-weight: bold;
    color: $white;
    position: relative;
    &:first-child{
      margin-left: 0px;
    }
    &.router-link-exact-active {
      color: $primary;
    }
    &:hover{
      color: $primary;
      cursor: pointer;
    }
    &:focus{
      color: $primary;
      cursor: pointer;
    }
    &:before{
      content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid $primary;
        width: 0%;
        transition: 0.5s ease all;
    }
  }
}
#nav a:hover:before{
  width: 100%;
}

//Mobile navigation

#mobile-nav{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: $bg;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  @media #{$small}{
    display: none;
  }
  @media #{$very_small}{
      text-align: center;
    }
  .menu {
    h6{
      padding: 24px;
      color: white;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0px;
      cursor: pointer;
      &:hover{
        color: $primary;
        cursor: pointer;
      }
      &:focus{
        color: $primary;
        cursor: pointer;
      }
    }
  }
}
.mobile-nav-body   {
  padding: 0;
  overflow: hidden;
  transition: 3s ease all !important;
  text-transform: uppercase;
  .mobile-nav-content{
    border-top: 1px solid $secondary;
    a{
      color: $white;
      display: block;
      padding: 10px;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
      &:focus {
        color: $secondary;
        text-decoration: none;
      }
    }
  .router-link-exact-active{
    color: $primary;
    font-weight: 700;
  }
  }
}
.is-closed .mobile-nav-body {
  max-height: 0;
}



</style>
