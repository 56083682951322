<template>
  <div class="home p-80">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="home-title">
            <h1>Jaroslav Petráš</h1>
            <h2>Web Developer</h2>
            <div class="social">
              <a :href="facebook.url" 
                  target="_blank" 
                  rel="noreferrer noopener" 
                  :title="facebook.title">
                  <i class="fab fa-facebook-square"></i>
              </a>
              <a :href="linkedin.url" 
                  target="_blank" 
                  rel="noreferrer noopener" 
                  :title="linkedin.title">
                  <i class="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setDocumentTitle from '../util'
export default {
  name: 'Home',
  data: ()=>{
    return{
      facebook: {
        url: 'https://www.facebook.com/webux.sk',
        title: 'Facebook',
      },
      linkedin: {
        url: 'https://www.linkedin.com/in/jaroslav-petráš-7b07bbba/',
        title: 'LinkedIn',  
      }
    }
  },
  mounted(){
      this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentTitle(this.$t("navigation.home"))
      },
      { immediate: true }
    )
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_mixins.scss";

.home{
  position: absolute !important;
  top: 75%;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
  .home-title{
    text-align: center;
    h1{
      animation: JP 2s  linear;
      font-weight: 900;
      font-size: 40px;
    }
    h2{
      font-size: 25px;
      animation: web 2s  linear;
      font-weight: 700;
    }
    .social{
      a{
        animation: social 3s  linear;
        font-size: 30px;
        color: $white;
        margin-left: 36px;
        &:first-child{
          margin-left: 0px;
        }
      }
    }
  }
  @keyframes JP{
    0%{
      opacity: 0;
      animation-delay: 2s;
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes web{
    0%{
      opacity: 0;
      animation-delay: 4s;
    }
    50%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes social{
    0%{
      opacity: 0;
      animation-delay: 6s;
    }
    50%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
}
.fa-facebook-square:hover{
  color:#3b5998;
  transition: 0.35s ease;
}
.fa-linkedin:hover{
  color: #2867B2;
  transition: 0.35s ease;
}

</style>
