<template>
  <div id="app">
    <Header/>
    <transition :name="transitionName"
         mode="out-in"
         @beforeLeave="beforeLeave"
         >
      <router-view />
    </transition>
  </div>
</template>


<script>
import Header from './components/Header'
const DEFAULT_TRANSITION = 'fade';

  export default {
    name: 'app',
    components:{
      Header,
      },
      data() {
      return {
        //Router slide in by scroll options
        prevHeight: 0,
        transitionName: DEFAULT_TRANSITION,
      };
    },
    //Router slide in options
    created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;
      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }
      this.transitionName = transitionName || DEFAULT_TRANSITION;
      next();
    });
    },
    methods: {
      //Router slide in options
      beforeLeave(element) {
         this.prevHeight = getComputedStyle(element).height;
    },
  }
  }
</script>

<style lang="scss">
@import './assets/scss/_variables.scss';
@import './assets/scss/_mixins.scss';
@import './assets/scss/_general';


//Router slide in per route
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(1000px, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-1000px, 0);
}
</style>
