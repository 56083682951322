import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import FlagIcon from 'vue-flag-icon';
import 'bootstrap/dist/css/bootstrap.min.css';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import Vuelidate from 'vuelidate';

Vue.config.productionTip = false
Vue.use(FlagIcon);
Vue.use(Vuelidate);


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
