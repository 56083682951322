import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {transitionName: 'slide'},
    component: Home, 
  },
  {
    path: '/omne',
    name: 'About',
    meta: { transitionName: 'slide'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/sluzby',
    name: 'Services',
    meta: { transitionName: 'slide'},
    component: () => import(/* webpackChunkName: "Services" */ '../views/Services.vue')
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    meta: { transitionName: 'slide'},
    component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio.vue')
  },
  {
    path: '/technologie',
    name: 'Technologies',
    meta: { transitionName: 'slide'},
    component: () => import(/* webpackChunkName: "Technologies" */ '../views/Technologies.vue')
  },
  {
    path: '/kontakt',
    name: 'Contact',
    meta: { transitionName: 'slide'},
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router
